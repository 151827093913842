exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-choose-country-tsx": () => import("./../../../src/templates/choose-country.tsx" /* webpackChunkName: "component---src-templates-choose-country-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-cookies-page-tsx": () => import("./../../../src/templates/cookies-page.tsx" /* webpackChunkName: "component---src-templates-cookies-page-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/form-page.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-job-offer-page-tsx": () => import("./../../../src/templates/job-offer-page.tsx" /* webpackChunkName: "component---src-templates-job-offer-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/news-page.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-offers-list-page-tsx": () => import("./../../../src/templates/offers-list-page.tsx" /* webpackChunkName: "component---src-templates-offers-list-page-tsx" */),
  "component---src-templates-search-center-page-tsx": () => import("./../../../src/templates/search-center-page.tsx" /* webpackChunkName: "component---src-templates-search-center-page-tsx" */)
}

